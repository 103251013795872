import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTaskProgress } from '../contexts/TaskProgressContext';
import { Spinner } from 'react-bootstrap';
import mqtt from 'mqtt';
import { useTaskProgressDispatch } from '../contexts/TaskProgressContext';
import { useLocation } from 'react-router-dom';

const ProgressOverlay = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
  width: 320px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 16px;
  animation: slideInLeft 0.3s ease-out;

  @keyframes slideInLeft {
    from {
      transform: translateX(-100px);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid #f0f0f0;
`;

const Title = styled.div`
  font-size: 14px;
  color: #000;
  font-weight: normal;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  color: #666;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  
  &:hover {
    background-color: #f5f5f5;
    color: #000;
  }
`;

const ProgressItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
`;

const ProgressIcon = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #f0f4ff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  color: #1677ff;
`;

const ProgressContent = styled.div`
  flex: 1;
  min-width: 0;
`;

const ProgressText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;

const ProgressLabel = styled.span`
  color: #000;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProgressStatus = styled.span`
  color: ${props => {
    switch (props.status) {
      case 'done':
        return '#52c41a';
      case 'processing':
        return '#1677ff';
      default:
        return '#666';
    }
  }};
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 4px;
  background-color: #f5f5f5;
  border-radius: 2px;
  overflow: hidden;
`;

const ProgressBarFill = styled.div`
  height: 100%;
  background-color: #1677ff;
  width: ${props => props.progress}%;
  transition: width 0.3s ease;
`;

const StyledSpinner = styled(Spinner)`
  width: 14px;
  height: 14px;
  margin-left: 4px;
  border-width: 2px;
`;

const CheckIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path d="M11.6668 3.5L5.25016 9.91667L2.3335 7" stroke="#52c41a" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const UserIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="#1677ff">
    <path d="M7 7C8.38071 7 9.5 5.88071 9.5 4.5C9.5 3.11929 8.38071 2 7 2C5.61929 2 4.5 3.11929 4.5 4.5C4.5 5.88071 5.61929 7 7 7Z"/>
    <path d="M7 8.5C4.66375 8.5 0 9.67125 0 12V13.5H14V12C14 9.67125 9.33625 8.5 7 8.5Z"/>
  </svg>
);

const STORAGE_KEY = 'taskProgressState';
const COMPLETION_VIEWED_KEY = 'completionViewed'; // New key for tracking viewed status

const isValidStoredState = (state) => {
    return state &&
           typeof state === 'object' &&
           'userId' in state &&
           'progress' in state &&
           'status' in state &&
           'message' in state &&
           'timestamp' in state &&
           Date.now() - state.timestamp < 24 * 60 * 60 * 1000;
};

const FloatingTaskProgress = () => {
    const { showProgress, status: contextStatus, message: contextMessage, userId } = useTaskProgress();
    const dispatch = useTaskProgressDispatch();
    const [progress, setProgress] = useState(0);
    const [status, setStatus] = useState('processing');
    const [message, setMessage] = useState('');
    const [mqttClient, setMqttClient] = useState(null);
    const [isVisible, setIsVisible] = useState(true);
    const location = useLocation();
    const showProgressBar = location.state?.showHeader || false;
    
    
    
    // Load saved state on mount
    useEffect(() => {
        const loadSavedState = () => {
            try {
                const savedState = localStorage.getItem(STORAGE_KEY);
                const completionViewed = localStorage.getItem(COMPLETION_VIEWED_KEY);
                
                if (savedState) {
                    const parsedState = JSON.parse(savedState);
                    
                    if (isValidStoredState(parsedState)) {
                        // Don't show if it's completed and already viewed
                        if (parsedState.status === 'done' && completionViewed === 'true') {
                            setIsVisible(false);
                            return false;
                        }
                        
                        // Restore the state through context
                        dispatch({
                            type: 'RESTORE_STATE',
                            payload: parsedState
                        });
                        
                        // Update local state
                        setProgress(parsedState.progress);
                        setStatus(parsedState.status);
                        setMessage(parsedState.message);
                        
                        return true;
                    }
                }
                return false;
            } catch (error) {
                console.error('Error loading saved state:', error);
                return false;
            }
        };

        loadSavedState();
    }, [dispatch]);

    // Handle auto-hide on completion
    useEffect(() => {
        let hideTimeout;
        
        if (status === 'done' && isVisible) {
            hideTimeout = setTimeout(() => {
                setIsVisible(false);
                localStorage.setItem(COMPLETION_VIEWED_KEY, 'true');
                dispatch({ type: 'HIDE_PROGRESS' });
            }, 5000);
        }

        return () => {
            if (hideTimeout) {
                clearTimeout(hideTimeout);
            }
        };
    }, [status, isVisible, dispatch]);

    // Handle MQTT connection and messages
    useEffect(() => {
        if (!userId) return;

        const client = mqtt.connect(process.env.REACT_APP_MQTT_URL, {
            clientId: `web_${userId}_${Math.random().toString(16).slice(2)}`,
            clean: true,
            connectTimeout: 4000,
            reconnectPeriod: 1000,
        });

        client.on('connect', () => {
            console.log('Connected to MQTT broker');
            const topic = `task_progress/${userId}/#`;
            client.subscribe(topic, (err) => {
                if (err) console.error('Subscription error:', err);
            });
        });

        client.on('message', (topic, messageBuffer) => {
            try {
                const data = JSON.parse(messageBuffer.toString());
                const newProgress = data.progress !== undefined ? data.progress : progress;
                const newStatus = newProgress === 100 ? 'done' : 'processing';
                
                // Create new state object
                const newState = {
                    userId,
                    progress: newProgress,
                    status: newStatus,
                    message: data.message || message,
                    timestamp: Date.now(),
                };

                // Reset completion viewed status when new progress comes in
                if (newProgress < 100) {
                    localStorage.removeItem(COMPLETION_VIEWED_KEY);
                }

                // Update local storage
                localStorage.setItem(STORAGE_KEY, JSON.stringify(newState));

                // Update local state
                setProgress(newProgress);
                setStatus(newStatus);
                setMessage(data.message || message);
                setIsVisible(true);

                // Update context
                dispatch({
                    type: 'UPDATE_PROGRESS',
                    status: newStatus,
                    message: data.message || message,
                    progress: newProgress
                });
            } catch (error) {
                console.error('Error processing MQTT message:', error);
            }
        });

        setMqttClient(client);

        return () => {
            if (client) {
                client.end();
            }
        };
    }, [userId, dispatch, progress, message]);

    const handleClose = () => {
        setIsVisible(false);
        localStorage.setItem(COMPLETION_VIEWED_KEY, 'true');
        dispatch({ type: 'HIDE_PROGRESS' });
    };

    if (!userId || !isVisible || (!showProgress && !progress)) return null;

    const isProcessing = status === 'processing';
    const isDone = status === 'done';

    return (
        <ProgressOverlay>
            <Header>
                <Title>{isProcessing ? 'Processing: 1' : 'Done: 1'}</Title>
                <CloseButton onClick={handleClose}>✕</CloseButton>
            </Header>
            <ProgressItem>
                <ProgressIcon>
                    <UserIcon />
                </ProgressIcon>
                <ProgressContent>
                    <ProgressText>
                        <ProgressLabel>
                            {'Uploading your own Prospects List'}
                        </ProgressLabel>
                        <ProgressStatus status={status}>
                            {isProcessing && (
                                <>
                                    <StyledSpinner animation="border" variant="primary" />
                                </>
                            )}
                            {isDone && (
                                <>
                                    Done
                                    <CheckIcon />
                                </>
                            )}
                        </ProgressStatus>
                    </ProgressText>
                    {isProcessing && (
                        <ProgressBarContainer>
                            <ProgressBarFill progress={progress} />
                        </ProgressBarContainer>
                    )}
                </ProgressContent>
            </ProgressItem>
        </ProgressOverlay>
    );
};

export default FloatingTaskProgress;