// Test environment price IDs
export const testPriceIds = {
  tier1000: 'price_1PvG1LJbSDxwoE5WZYQyjPMS',
  tier5000: 'price_1PvG1uJbSDxwoE5WXzXGt2zG',
  tier10000: 'price_1PvG2JJbSDxwoE5WMEaJUtdE',
  tier25000: 'price_1PvG2gJbSDxwoE5Wdp0Ia9sk',
  tier50000: 'price_1PvG30JbSDxwoE5WNrHyNDul',
  tier100000: null,
  tier200000: null,
  tier300000: null,
  tier500000: null
};

// Production environment price IDs
export const livePriceIds = {
  tier1000: 'price_1QRTUkJbSDxwoE5Wp2ydqHjS',
  tier5000: 'price_1QRTUkJbSDxwoE5WIgH8UYcC',
  tier10000: 'price_1QRTUkJbSDxwoE5WKe3Ofdr5',
  tier25000: 'price_1QRTUkJbSDxwoE5W1FbDurMU',
  tier50000: 'price_1QRTUkJbSDxwoE5WA3ve974E',
  tier100000: 'price_1QRTW7JbSDxwoE5WGEEtRyA8',
  tier200000: 'price_1QRTWnJbSDxwoE5WJbBUjOBL',
  tier300000: 'price_1QRTX5JbSDxwoE5WQx8VI24l',
  tier500000: null
};

// Helper function to get price ID based on contacts and environment
export const getPriceId = (contacts, isProd = process.env.REACT_APP_ENV === 'production') => {
  const priceIds = isProd ? livePriceIds : testPriceIds;
  
  switch (true) {
    case contacts <= 1000:
      return priceIds.tier1000;
    case contacts <= 5000:
      return priceIds.tier5000;
    case contacts <= 10000:
      return priceIds.tier10000;
    case contacts <= 25000:
      return priceIds.tier25000;
    case contacts <= 50000:
      return priceIds.tier50000;
    case contacts <= 100000:
      return priceIds.tier100000;
    case contacts <= 200000:
      return priceIds.tier200000;
    case contacts <= 300000:
      return priceIds.tier300000;
    default:
      return priceIds.tier500000;
  }
};
