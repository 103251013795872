import React, { useState, useEffect } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import axios from "axios";
import {
  Form,
  message,
  Card,
  Breadcrumb,
  Tabs,
  Avatar,
  Row,
  Col,
  Button,
  Input,
  Result
} from "antd";
import { UserOutlined, MessageOutlined, CloseOutlined, SendOutlined, CalendarOutlined } from "@ant-design/icons";
import { InlineWidget } from "react-calendly";

import ContentTab from "../components/ContentTab";
import StylingTab from "../components/StylingTab";
import GoalsTab from "../components/GoalsTab";
import KnowledgeBaseTab from "../components/KnowledgeBaseTab";
import EmbedTab from "../components/EmbedTab";
import ConnectToDatabaseTab from '../components/ConnectToDatabaseTab';
import { useSelector } from 'react-redux';
import { Spinner } from "react-bootstrap";
import "./AIChat.css"
const { TabPane } = Tabs;

const EnquiryComponent=({isSubmittedSuccess,onSubmitEnquiry,savingContact,enquiryForm})=>{
  return (
    <>
    {
      isSubmittedSuccess==false?(

        <Card>
    <Form form={enquiryForm}>
    <Form.Item name='name'
    rules={[
      { required: true, message: 'Please enter the name' },
      { whitespace: true, message: 'Name cannot be empty spaces' }
    ]}
    >
    <Input
    placeholder="Enter Name"
    />
  
    </Form.Item>
    <Form.Item
    name='email'
    rules={[
      { required: true, message: 'Please enter the email' },
      { type: 'email', message: 'Please enter a valid email' },
      { whitespace: true, message: 'Email cannot be empty spaces' }
    ]}
    >
    <Input
    placeholder="Enter Email"
    />
    </Form.Item>
    <Form.Item
    name='company_name'
    rules={[
      { required: true, message: 'Please enter the company name' },
      { whitespace: true, message: 'Company name cannot be empty spaces' }
    ]}
    >
    <Input
          placeholder="Enter Company Name"
          />
    </Form.Item>
    <Button block type="primary" onClick={onSubmitEnquiry} style={{marginBottom:'5px'}} loading={savingContact} name="Submit">  {savingContact ? 'Saving...' : 'Submit'}</Button>
    </Form>
    </Card>
      ):(
        <Card>
          <Result
            status="success"
            title="Successfully Submitted!"
          />
        </Card>
      )
    }
    </>
  )
}

const CalendlyComponent=({calendlyUrl,showCalendly})=>{
  return (
    <>
    {
      showCalendly && (

    <Card style={{ padding: 0 }}>
    <div style={{ height: '400px' }}> 
      <InlineWidget
        url={calendlyUrl}
        styles={{
          height: '100%',
          width: '100%'
        }}
      />
    </div>
  </Card>
      )
    }
    </>
  )
}
const LoadingDots = () => {
  return (
    <div className="loader">
      <span></span>
      <span></span>
      <span></span>
      <style>{`
        .loader {
          display: flex;
          gap: 8px;
          padding: 16px;
        }
        
        .loader span {
          display: inline-block;
          width: 10px;
          height: 10px;
          background: #007bff;
          border-radius: 50%;
          animation: loader 0.8s infinite alternate;
        }
        
        .loader span:nth-of-type(2) {
          animation-delay: 0.2s;
        }
        
        .loader span:nth-of-type(3) {
          animation-delay: 0.6s;
        }
        
        @keyframes loader {
          0% {
            opacity: 0.9;
            transform: scale(0.5);
          }
          100% {
            opacity: 0.1;
            transform: scale(1);
          }
        }
      `}</style>
    </div>
  );
};


const AIChatDetail = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [chatSession, setChatSession] = useState(null);
  const [formDetails, setFormDetails] = useState({});
  const [activeTab, setActiveTab] = useState("preview");
  const [activecomponentTab, setActiveComponentTab] = useState("content");
  const [extraFields, setextraFields] = useState([])
  const { userId } = useSelector(state => state.app);
  const [chatLoading,setchatLoading]=useState(false)
  const [enquiryDetails,setenquiryDetails]=useState({})
  const [isSubmittedSuccess,setisSubmittedSuccess]=useState(false)
  const [enquiryForm] = Form.useForm();
  const [savingContact, setSavingContact] = useState(false);
  console.log("activecomponentTab------>",activecomponentTab);
  
 
  
  const [previewMessages, setPreviewMessages] = useState([
    {
      type: "user",
      content: "I have a question about your products.",
    },
    {
      type: "bot",
      content: "Of course! I'd be happy to help you with any questions about our products. What specific information are you looking for?",
    },
  ]);
  const [testingMessages, setTestingMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [codeParam, setCodeParam] = useState(null);
  const [showCalendly, setShowCalendly] = useState(false);
  const [calendlyUrl, setCalendlyUrl] = useState("");

  const botToneOfVoice = [
    { value: 0, tone: "neutral" },
    { value: 1, tone: "Formal and Professional" },
    { value: 2, tone: "Inspirational and motivational" },
    { value: 3, tone: "Emphathetic and supportive" },
    { value: 4, tone: "Educational and informative" },
    { value: 5, tone: "Convertional and Friendly" },
  ];

  useEffect(() => {
    const fetchChatBot = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/aichat/chatbots/${id}/`
        );
        console.log("response.data--->", response.data);
        
        const initialValues = {
          ...response.data,
          company_logo: response.data.company_logo
            ? [
                {
                  uid: "-1",
                  name: "company_logo.png",
                  status: "done",
                  url: response.data.company_logo,
                },
              ]
            : [],
          bot_avatar: response.data.bot_avatar
            ? [
                {
                  uid: "-1",
                  name: "bot_avatar.png",
                  status: "done",
                  url: response.data.bot_avatar,
                },
              ]
            : [],
          chat_icon: response.data.chat_icon
            ? [
                {
                  uid: "-1",
                  name: "chat_icon.png",
                  status: "done",
                  url: response.data.chat_icon,
                },
              ]
            : [],
          
        };
        setFormDetails(initialValues);

        const newMessage = {
          type: "bot",
          content: response.data.welcome_message
        };

        setPreviewMessages(prevMessages => [newMessage, ...prevMessages]);
        setTestingMessages([newMessage]);
        form.setFieldsValue(initialValues);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching chat bot:", error);
        message.destroy()
        message.error("Failed to load chat bot details");
        setLoading(false);
      }
    };

    fetchChatBot();
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');
    if (code) {
      setActiveComponentTab("goals");
      setCodeParam(code);
    }
  }, [id, form,location]);

  const handleFormChange = (changedValues, allValues) => {
    console.log("changedValues", changedValues);
    
    setFormDetails(prevDetails => ({
      ...prevDetails,
      ...changedValues
    }));

    if (changedValues?.welcome_message !== undefined) {
      console.log("triggered", changedValues?.welcome_message);
      setPreviewMessages(prevMessages => {
        const updatedMessages = [...prevMessages];
        updatedMessages[0] = {
          type: "bot",
          content: changedValues?.welcome_message,
        };
        return updatedMessages;
      });
      setTestingMessages(prevMessages => {
        const updatedMessages = [...prevMessages];
        updatedMessages[0] = {
          type: "bot",
          content: changedValues?.welcome_message,
        };
        return updatedMessages;
      });
    }
  };
  
  
  useEffect(() => {
    const handleCalendlyEvent = async (e) => {
        if (e.data.event && e.data.event === "calendly.event_scheduled") {
    
            const eventDetails = e.data.payload;
            console.log("eventDetails",eventDetails);
            
    
            const eventUri = eventDetails.event.uri;
            const inviteeUri = eventDetails.invitee.uri;
      
    
            try {
                // Fetch event details
                const eventResponse = await axios.get(eventUri, {
                    headers: {
                        'Authorization': `Bearer ${process.env.REACT_APP_CALENDLY_API_KEY_AICHAT}`,
                        'Content-Type': 'application/json'
                    }
                });
    
                const eventData = eventResponse.data;
                const startTime = eventData.resource.start_time;
                const endTime = eventData.resource.end_time;
                // Fetch invitee details to get the email
                const inviteeResponse = await axios.get(inviteeUri, {
                  headers: {
                      'Authorization': `Bearer ${process.env.REACT_APP_CALENDLY_API_KEY_AICHAT}`,
                      'Content-Type': 'application/json'
                  }
              });

              const inviteeData = inviteeResponse.data;
              const inviteeEmail = inviteeData.resource.email;
              const inviteeName = inviteeData.resource.name;
              console.log("inviteeEmail--->",inviteeEmail);
              console.log("inviteeName--->",inviteeName);
              

                await axios.post(process.env.REACT_APP_API_URL + '/meetings/schedule_meeting', {
                    eventUri: eventUri,
                    inviteeUri: inviteeUri,
                    startTime: startTime,
                    endTime: endTime,
                    documentId: id,
                    email: inviteeEmail,
                    name:inviteeName,
                    user_id: userId
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
            } catch (error) {
                console.error('Error processing Calendly event:', error);
            }
        }
    };

    window.addEventListener('message', handleCalendlyEvent);

    return () => {
        window.removeEventListener('message', handleCalendlyEvent);
    };
}, []);

  const onFinish = async (values) => {
    
    // Handle the case where contact_information is null
    const updatedValues = {
      ...values,
      contact_information: values.contact_information || [],
      knowledge_bases:values.knowledge_bases || [],
      user_id: userId
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/aichat/chatbots/${id}/`,
        updatedValues
      );
      setFormDetails(response.data);
      message.success("Chat bot updated successfully");
    } catch (error) {
      console.error("Error updating chat bot:", error);
      message.error("Failed to update chat bot");
    }
  };

  const onStylingFinish = async (values) => {
    try {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (
          key === "company_logo" ||
          key === "bot_avatar" ||
          key === "chat_icon"
        ) {
          if (values[key] && values[key][0] && values[key][0].originFileObj) {
            formData.append(key, values[key][0].originFileObj);
          }
        } else {
          formData.append(key, values[key]);
        }
      });

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/aichat/chatbots/${id}/styling/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setFormDetails((prevState) => ({ ...prevState, ...response.data }));
      message.success("Chat bot styling updated successfully");
    } catch (error) {
      console.error("Error updating chat bot styling:", error);
      message.error("Failed to update chat bot styling");
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  console.log("extrafields--->",extraFields);
  

  const handleSendMessage = async () => {
    if (userInput.trim() && activeTab === "testing") {
      const newUserMessage = { type: "user", content: userInput };
      setTestingMessages([...testingMessages, newUserMessage]);
      setUserInput("");
      setchatLoading(true)
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/aichat/aichat_response/`,
          {
            chatbot_id: id,
            message: userInput,
            chat_session:chatSession,
            showCalendly:showCalendly
          }
        );
        console.log("chat_session--->",chatSession);
        
        setChatSession(response.data.chat_session)
        const botResponse = { type: "bot", content: response.data.reply };
        setTestingMessages(prev => [...prev, botResponse]);
        if (response.data.extra_fields=='meeting' && !extraFields.includes('meeting') && showCalendly==false){
          toggleCalendly()
          setextraFields([...extraFields,'meeting'])
          
        }
        if (response.data.extra_fields=='email_enquiry' && !extraFields.includes('email_enquiry')){
          setextraFields([...extraFields,'email_enquiry'])
          const comp={type:"component",content:"email_enquiry"}
          setTestingMessages(prev => [...prev, comp]);

        }
      } catch (error) {
        console.error("Error getting AI response:", error);
        message.error("Failed to get AI response");
        const errorMessage = { type: "bot", content: "Sorry, I encountered an error while processing your request." };
        setTestingMessages(prev => [...prev, errorMessage]);
      }
      finally{
        setchatLoading(false)
      }
    }
  };

  // Function to calculate the brightness of a color
  const getBrightness = (hexColor) => {
    console.log("hexColor",hexColor);
    
    const rgb = parseInt(hexColor.slice(1), 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;
    return (r * 299 + g * 587 + b * 114) / 1000;
  };

  // Function to determine text color based on background brightness
  const getTextColor = (hexColor) => {
    if (hexColor){
      return getBrightness(hexColor) > 128 ? "#000000" : "#ffffff";
    }
  };
  console.log("formDetails data--->",formDetails);
  

  // Function to get the image URL (either from S3 or local file)
  const getImageUrl = (image) => {
    // if (typeof image === 'string') {
    //   // If it's a string, assume it's an S3 URL
    //   return image;
    // } 
    if (Array.isArray(image) && image.length > 0) {
      // If it's an array (from Upload component), get the URL
      if (image[0].originFileObj){
        return image[0].url || (image[0].originFileObj && URL.createObjectURL(image[0].originFileObj));
      }

    }
    return null;
  };

  const onSubmitEnquiry=async ()=>{

    try {
      const values = await enquiryForm.validateFields();
      setSavingContact(true);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/emailapp/store_enquiry_contact`, {
        user_id: userId,
        ...values,
        chatSession
      });
      console.log("values--->",values);
      
      if (response.status === 201) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/aichat/aichat_response/`,
            {
              chatbot_id: id,
              message: `i have Submitted the form details :${JSON.stringify(values)}`,
              chat_session:chatSession,
            }
          );
        const botResponse = { type: "bot", content: response.data.reply };
        setTestingMessages(prev => [...prev, botResponse]);
        }
        catch(error){
          console.error("Error getting AI response:", error);
        message.error("Failed to get AI response");
        const errorMessage = { type: "bot", content: "Sorry, I encountered an error while processing your request." };
        setTestingMessages(prev => [...prev, errorMessage]);

        }


        enquiryForm.resetFields()
        setisSubmittedSuccess(true) 
      } else {
        message.error('Failed to save contact');
      }
  
    }catch (error) {
      if (error.response) {
        message.error(error.response.data.message || 'Failed to save contact');
      } else if (!error.isAxiosError) {
        console.error('Validation failed:', error);
        return;
      } else {
        message.error('Failed to save contact');
      }
      console.error('Error saving contact:', error);
    } finally {
      setSavingContact(false);
    }

    
    
  }

  // Function to toggle Calendly widget
  const toggleCalendly = async () => {
    console.log("wait for calendly",showCalendly);
    
    if (!showCalendly) {
      try {
        console.log("wait for calendly");
        
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/aichat/get_calendly_url_ai_chat`, {
          document_id: id
        });
        if (response.data && response.data.scheduling_url) {
          setCalendlyUrl(response.data.scheduling_url);
          setShowCalendly(true);
          const comp={type:"component",content:"calendly_widget"}
          setTestingMessages(prev => [...prev, comp]);
        } else {
          message.error("Failed to fetch Calendly URL");
        }
      } catch (error) {
        console.error('Error fetching Calendly URL:', error);
        message.error("Failed to fetch Calendly URL");
      }
    } else {
      setShowCalendly(false);
    }
  };

  if (loading) {
    return <div className="d-flex justify-content-center align-items-center w-full" style={{height: 'calc(100vh - 61px)'}}>
      <Spinner animation="border" role="status">
    <span className="visually-hidden">Loading...</span>
  </Spinner>
    </div>;
  }

  const renderCompanyHeader = () => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Avatar
        style={{
          backgroundColor: formDetails.primary_color || '#1890ff',
          marginRight: '8px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        size={40}
        src={formDetails?.company_logo?.[0]?.url}
      />
        {/* {!getImageUrl(formDetails.company_logo) && formDetails.company_name?.charAt(0).toUpperCase()} */}
      {/* </Avatar> */}
      <div style={{ fontWeight: 'bold', fontSize: '18px' }}>
        {formDetails.company_name}
      </div>
    </div>
  );

  const renderPreview = () => {
    const messages = activeTab === "preview" ? previewMessages : testingMessages;
  
  return (
    <div
      style={{
        position: 'relative',
        height: '100%',
        padding: '15px'
      }}
    >
      {formDetails?.widget_default_state === 'open' ? (
        <div
          style={{
            borderRadius: "15px",
            height: "100%",
            display: 'flex',
            flexDirection: 'column',
            boxShadow: '0 0 15px #ddd'
          }}
        >
          <div style={{ borderBottom: "1px solid #ddd", padding: "10px 20px", fontWeight: "bold", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {/* <span>{formDetails?.name} Preview</span> */}
            {renderCompanyHeader()}
            <Button
              type="text"
              icon={<CloseOutlined />}
              onClick={() => setFormDetails({...formDetails, widget_default_state: 'closed'})}
            />
          </div>
          
          <div
            style={{
              flexGrow: 1,
              overflowY: "auto",
              marginBottom: "16px",
              borderRadius: "4px",
              padding: "10px 20px",
            }}
          >
            
            {/* {(activeTab === "preview" ? previewMessages : testingMessages).map((msg, index) => ( */}
            {messages.map((msg, index) => (
              <React.Fragment key={index}>
                
              <div
                key={index}
                style={{
                  marginBottom: "16px",
                  textAlign: msg.type === "user" ? "right" : "left",
                }}
              >
                <div
                  style={{
                    fontSize: "12px",
                    marginBottom: "4px",
                    // color: msg.type === "user" ? "#1890ff" : "#52c41a",
                    color: formDetails?.primary_color,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: msg.type === "user" ? "flex-end" : "flex-start",
                  }}
                >
                  {msg.type === "bot" && (
                    <Avatar
                      style={{ marginRight: "8px" }}
                      src={formDetails?.bot_avatar?.[0]?.url}
                      icon={<UserOutlined />}
                    />
                    //   {/* {!getImageUrl(formDetails.bot_avatar) && formDetails?.agent_name?.charAt(0).toUpperCase()}
                    // </Avatar> */}
                  )}
                  {
                    msg.type !=='component' && (
                      msg.type === "user" ? "User" : formDetails?.agent_name || "Bot"
                    )
                  }
                  {/* {msg.type === "user" ? "User" : formDetails?.agent_name || "Bot"}       */}
                       
                </div>
                {
                  msg.type !=='component' && (
                <div
                  style={{
                    display: "inline-block",
                    maxWidth: "70%",
                    padding: "8px",
                    borderRadius: "12px",
                    marginLeft: "35px",
                    backgroundColor: msg.type === "user" ? formDetails?.primary_color : "#f0f0f0",
                    color: msg.type === "user" ? getTextColor(formDetails?.primary_color) : "#000000",
                  }}
                >
                  {msg.content}
                </div>
                  )
                }
              </div>
                {msg.type=='component' && msg.content=='email_enquiry' && (
                  <EnquiryComponent isSubmittedSuccess={isSubmittedSuccess} onSubmitEnquiry={onSubmitEnquiry} savingContact={savingContact} enquiryForm={enquiryForm}/>
                )}
                {msg.type=='component' && msg.content=='calendly_widget' && (
                  <CalendlyComponent calendlyUrl={calendlyUrl} showCalendly={showCalendly}/>
                )}
              </React.Fragment>
            ))}
            {
              chatLoading && (<LoadingDots/>)
            }
            <div style={{margin:2,padding:2}}>

            {(activeTab !== "preview") && (formDetails?.is_action_button==true) && (
              <Button
                type="primary"
                size="small"
                href={formDetails?.action_button_url}
                target="_blank"
                style={{
                  display: "block",
            
                }}
              >

                {formDetails?.action_button}
              </Button>
            )}
            </div>
          </div>
          <div style={{ display: "flex", padding: "10px", borderTop: "1px solid #ddd" }}>
          {(activeTab !== "preview") && (formDetails?.is_pin_meeting==true) && (showCalendly==false) && (
              <Button
                size="small"
                onClick={toggleCalendly}
                style={{
                  position: "absolute",
                  bottom: "90px",
                  right: "50px",
                  borderRadius: "100px",
                  height: "auto",
                }}
                className="px-3 py-2"
                icon={<CalendarOutlined />}
              >
                Book a Meeting
              </Button>
            )}
            <Input
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onPressEnter={handleSendMessage}
              placeholder={formDetails?.input_placeholder || "Type your message..."}
              style={{ marginRight: "8px", border: "none", background: "transparent" }}
              disabled={activeTab === "preview" || chatLoading}
            />
            {userInput.trim() !== "" && (
            <Button
              type="primary"
              shape="circle"
              icon={<SendOutlined />}
              onClick={handleSendMessage}
              disabled={activeTab === "preview" || chatLoading}
            >
              
            </Button>
            )}
          </div>
        </div>
      ) : (
        <Button
          type="primary"
          shape="circle"
          size="large"
          onClick={() => setFormDetails({...formDetails, widget_default_state: 'open'})}
          style={{
            position: 'absolute',
            bottom: '20px',
            right: formDetails?.widget_position === 'right' ? '20px' : undefined,
            left: formDetails?.widget_position === 'left' ? '20px' : undefined,
            width: '60px',
            height: '60px',
            padding: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
          }}
        >
          {(formDetails?.chat_icon?.[0]?.url) ? (
            <img
              src={formDetails?.chat_icon?.[0]?.url}
              alt="Chat Icon"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          ) : (
            <MessageOutlined style={{ fontSize: '24px' }} />
          )}
        </Button>
      )}
      
    </div>
  );
    }

  const tabContentStyle = {
    height: 'calc(100vh - 200px)',
    overflowY: 'auto',
  };

  return (
    <>
    <div className="card m-4 border-0">
      <div className="card-body">
      <div className="ai-chat-detail" style={{ height: 'calc(100vh - 150px)', display: 'flex', flexDirection: 'column' }}>
      <div className='d-flex justify-content-between align-items-center'>
        <h5>{formDetails?.name}</h5>
        <Breadcrumb style={{ marginBottom: "16px" }}>
          <Breadcrumb.Item>
            <Link to="/chats">AI Chat</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{formDetails?.name}</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <Row gutter={16} align="top" style={{ flex: 1, overflow: 'hidden' }}>
        <Col  sm={14} md={14} lg={16} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <Tabs activeKey={activecomponentTab} onChange={setActiveComponentTab} destroyInactiveTabPane style={{ flex: 1, display: 'flex', flexDirection: 'column'}} className="custom-tabs">
            <TabPane tab="Content" key="content" style={tabContentStyle}>
              <ContentTab
                form={form}
                onFinish={onFinish}
                document_id={id}
                user_id={userId}
                handleFormChange={handleFormChange}
                formDetails={formDetails}
                botToneOfVoice={botToneOfVoice}
              />
            </TabPane>

            <TabPane tab="Styling" key="styling" style={tabContentStyle}>
              <StylingTab
                form={form}
                onStylingFinish={onStylingFinish}
                handleFormChange={handleFormChange}
                formDetails={formDetails}
                document_id={id}
                user_id={userId}
                normFile={normFile}
              />
            </TabPane>
            <TabPane tab="Goals" key="goals" style={tabContentStyle}>
              <GoalsTab
                form={form}
                document_id={id}
                onFinish={onFinish}
                handleFormChange={handleFormChange}
                formDetails={formDetails}
                codeParam={codeParam}
              />
            </TabPane>
            <TabPane tab="Knowledge Base" key="knowledge-base" style={tabContentStyle}>
              <KnowledgeBaseTab 
              document_id={id} 
              onFinish={onFinish}
              handleFormChange={handleFormChange}
              formDetails={formDetails}
              />
            </TabPane>
            <TabPane tab="Connect to Database" key="connecttodb" style={tabContentStyle}>
                <ConnectToDatabaseTab document_id={id}/>
            </TabPane>
            <TabPane tab="Embed" key="embed" style={tabContentStyle}>
              <EmbedTab chatbotId={id} />
            </TabPane>
          </Tabs>
        </Col>
        <Col sm={10} md={10} lg={8} style={{ height: '100%', overflowY: 'auto' }}>
          <Tabs activeKey={activeTab} onChange={setActiveTab} style={{ height: '100%', overflowY: 'auto' }}>
            <TabPane tab="Preview" key="preview">
              {renderPreview()}
            </TabPane>
            <TabPane tab="Testing" key="testing">
              {renderPreview()}
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </div>
      </div>
    </div>
    </>
  );
};

export default AIChatDetail;
