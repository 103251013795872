import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Breadcrumb, Drawer, Spin, Steps } from 'antd';
import { FaLinkedin, FaFacebook, FaTwitter, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import styled from "styled-components";
import {
  ApartmentOutlined,
  CalendarOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  FileSearchOutlined,
  MailOutlined,
  RobotOutlined,
  RocketOutlined,
  UserOutlined,
  MessageOutlined
} from "@ant-design/icons";
import { SideMenu } from '../components/Common/SideMenu';
import { Button, Container, Spinner } from "react-bootstrap";
import { useSelector } from 'react-redux';

// Styled components
const PageBackground = styled.div`
  background-color: #f4f4f4;
  min-height: 100vh;
  padding: 20px 20px;
`;

const Card = styled.div`
  background: #fff;
  border-radius: 12px;
  padding: 30px;
  transition: all 0.3s ease;
`;

const EmailThread = styled.div`
  margin-bottom: 20px;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  overflow: hidden;
`;

const EmailHeader = styled.div`
  padding: 15px;
  background-color: #f8f9fa;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const EmailContent = styled.div`
  padding: 15px;
  border-top: 1px solid #e6e6e6;
`;

const EmailMetadata = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
`;

const Badge = styled.span`
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  ${props => {
    switch (props.type) {
      case 'follow_up':
        return 'background-color: #e3f2fd; color: #1976d2;';
      case 'not_opened':
        return 'background-color: #fff3e0; color: #f57c00;';
      case 'opened':
        return 'background-color: #e8f5e9; color: #2e7d32;';  // Green color for opened
      case 'reply':
        return 'background-color: #e8f5e9; color: #2e7d32;';
      case 'first_step':
        return 'background-color: #e3f2fd; color: #1976d2;';
      default:
        return 'background-color: #f5f5f5; color: #616161;';
    }
  }}
`;

const ProspectReply = styled.div`
  margin: 15px 0;
  padding: 15px;
  background-color: #e8f5e9;
  border-left: 3px solid #4caf50;
  border-radius: 4px;

  .reply-content {
    font-size: 14px;
    color: #1a1a1a;
    line-height: 1.5;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
`;

const ProfileSection = styled.div`
  display: flex;
  align-items: start;
  gap: 15px;
  margin-bottom: 20px;
`;

const EmailDisplay = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { documentId, personEmail, user_id, showHeader,fromLocation} = location.state;
  const [loading, setLoading] = useState(false);
  const [emailChain, setEmailChain] = useState([]);
  const [singleEmail, setSingleEmail] = useState({});
  const [companyDescription, setCompanyDescription] = useState('');
  const [companyName, setCompanyName] = useState(null);
  const [personName, setPersonName] = useState('');
  const [personFacebook, setPersonFacebook] = useState('');
  const [personTwitter, setPersonTwitter] = useState('');
  const [personLinkedin, setPersonLinkedin] = useState('');
  const [continueBtnLoading, setContinueBtnLoading] = useState(false);
  const { userId, basicInfo: basicInfoState } = useSelector(state => state.app);
  const [open, setOpen] = useState(false);
  const wsRef = useRef(null);
  const [expandedEmails, setExpandedEmails] = useState({});

  
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const processEmailChain = (rawEmailChain) => {
    const processedEmails = [];
    
    rawEmailChain?.forEach(email => {
      // Add the main email
      processedEmails.push({
        ...email,
        isMainEmail: true,
        reply_content: null // Remove reply_content as it will be its own entry
      });

      // If there's a reply_content, add it as a separate email
      if (email.reply_content) {
        processedEmails.push({
          id: `${email.id}-reply`,
          subject: `Re: ${email.subject}`,
          body: email.reply_content,
          timestamp: email.timestamp, // Use the same timestamp or adjust if needed
          sender: 'prospect',
          isReply: true
        });
      }

      // If there's a prospect_reply, add it as a separate email
      if (email.prospect_reply) {
        processedEmails.push({
          id: `${email.id}-prospect-reply`,
          subject: `Re: ${email.subject}`,
          body: email.prospect_reply.text,
          timestamp: email.prospect_reply.timestamp,
          sender: 'prospect',
          isReply: true
        });
      }

      // Add any follow-up replies
      if (email.replies?.length > 0) {
        email.replies.forEach(reply => {
          // Add the agent's reply
          processedEmails.push({
            ...reply,
            isReply: true,
            subject: `Re: ${email.subject}`,
            sender: 'agent'
          });

          // If there's a reply_content in the follow-up, add it as a separate email
          if (reply.reply_content) {
            processedEmails.push({
              id: `${reply.id}-reply`,
              subject: `Re: ${email.subject}`,
              body: reply.reply_content,
              timestamp: reply.timestamp,
              sender: 'prospect',
              isReply: true
            });
          }

          // If there's a prospect reply to this follow-up, add it as a separate email
          if (reply.prospect_reply) {
            processedEmails.push({
              id: `${reply.id}-prospect-reply`,
              subject: `Re: ${email.subject}`,
              body: reply.prospect_reply.text,
              timestamp: reply.prospect_reply.timestamp,
              sender: 'prospect',
              isReply: true
            });
          }
        });
      }
    });

    // Sort all emails by timestamp in descending order (newest first)
    return processedEmails.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
  };

  useEffect(() => {
    const generateEmail = async () => {
      setLoading(true);
      try {
        const response = await axios.post(process.env.REACT_APP_API_URL + '/integration/generate_custom_email', {
          person_email: personEmail,
          document_id: documentId,
          user_id: user_id
        });

        if (response.status === 200) {
          
          const processedEmails = processEmailChain(response.data.email_chain);
          setEmailChain(processedEmails);
          if(processedEmails.length>0){

            setSingleEmail(processedEmails[0])
          }
          
          setCompanyDescription(response.data.person_data.company_description);
          setCompanyName(response.data.person_data.company_name);
          setPersonName(response.data.person_data.name);
          setPersonFacebook(response.data.person_data.person_facebook || '');
          setPersonTwitter(response.data.person_data.person_twitter || '');
          setPersonLinkedin(response.data.person_data.person_linkedin || '');
          
          // Expand the latest email by default
          if (processedEmails.length > 0) {
            setExpandedEmails({ [processedEmails[0].id]: true });
          }
          
          setLoading(false);
        } else if (response.status === 202) {
          setCompanyDescription(response.data.person_data.company_description);
          setCompanyDescription(response.data.person_data.company_name);
          setPersonName(response.data.person_data.person_name);
          setPersonFacebook(response.data.person_data.person_facebook || '');
          setPersonTwitter(response.data.person_data.person_twitter || '');
          setPersonLinkedin(response.data.person_data.person_linkedin || '');
          handleWebSocketConnection(process.env.REACT_APP_WS_URL);
        }
      } catch (error) {
        console.error('Error generating email:', error);
        setLoading(false);
      }
    };

    generateEmail();
    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, []);

  const handleWebSocketConnection = (websocketUrl) => {
    const socket = new WebSocket(websocketUrl);
    wsRef.current = socket;

    socket.onmessage = (event) => {
      const responseData = JSON.parse(event.data);
      if (responseData.status === 'success') {
        const newEmail = {
          id: responseData.message.id,
          subject: responseData.message.subject,
          body: responseData.message.email_body,
          timestamp: new Date().toISOString(),
          sender: 'agent',
          agent_name: responseData.message.agent_name
        };
        setEmailChain(prevChain => [newEmail, ...prevChain]);
        setSingleEmail(newEmail)
        setExpandedEmails(prev => ({ ...prev, [newEmail.id]: true }));
        setCompanyDescription(responseData.message.person_data.company_info.short_description);
        setCompanyName(responseData.message.person_data.company_name);
        setPersonName(responseData.message.person_data.name);
        
        setPersonFacebook(responseData.message.person_data.facebook_url || '');
        setPersonTwitter(responseData.message.person_data.twitter_url || '');
        setPersonLinkedin(responseData.message.person_data.linkedin_url || '');
        setLoading(false);
        socket.close();
      }
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
      setLoading(false);
    };
  };

  const toggleEmail = (emailId) => {
    setExpandedEmails(prev => ({
      ...prev,
      [emailId]: !prev[emailId]
    }));
  };

  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const renderSocialIcons = () => (
    <SocialIcons>
      {personLinkedin && (
        <a href={personLinkedin} target="_blank" rel="noopener noreferrer">
          <FaLinkedin size={20} color="#0077b5" />
        </a>
      )}
      {personFacebook && (
        <a href={personFacebook} target="_blank" rel="noopener noreferrer">
          <FaFacebook size={20} color="#3b5998" />
        </a>
      )}
      {personTwitter && (
        <a href={personTwitter} target="_blank" rel="noopener noreferrer">
          <FaTwitter size={20} color="#1da1f2" />
        </a>
      )}
    </SocialIcons>
  );

  const handleContinue = async () => {
    setContinueBtnLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/agent_setup/update_review_samples`, {
        user_id: userId
      });
      if (basicInfoState && basicInfoState.current_plan) {
        navigate('/confirm-deploy-agents');
      } else {
        navigate('/pricing');
      }
    } catch (error) {
      console.error('Error updating review samples:', error);
    } finally {
      setContinueBtnLoading(false);
    }
  };

  const renderEmailMetadata = (email) => (
    <EmailMetadata>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginBottom: '8px' }}>
        <div>
          <span style={{ color: '#666', marginRight: '15px' }}>
            <strong>From:</strong> {email.sender === 'prospect' ? personName : 'Sales Agent'}
          </span>
          <span style={{ color: '#666' }}>
            <strong>To:</strong> {email.sender === 'prospect' ? 'Sales Agent' : personName}
          </span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <CalendarOutlined style={{ color: '#666' }} />
          <span style={{ color: '#666' }}>{formatDate(email.timestamp)}</span>
        </div>
      </div>
      <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
       
        {email.sequence_number > 1 && (
          <Badge type="sequence">#{email.sequence_number}</Badge>
        )}
      </div>
    </EmailMetadata>
  );
  
  const renderEmailContent = (email) => (
    <div dangerouslySetInnerHTML={{ __html: email.body }} />
  );

  const renderEmailChain = () => (
    <div className="space-y-4">
      {emailChain.map((email) => (
        <EmailThread key={email.id}>
          <EmailHeader onClick={() => toggleEmail(email.id)}>
            <div className="w-100">
              {renderEmailMetadata(email)}
              <h5 className="mb-0 mt-2">{email.subject}</h5>
            </div>
            {expandedEmails[email.id] ? 
              <FaChevronUp size={16} style={{ color: '#666' }} /> : 
              <FaChevronDown size={16} style={{ color: '#666' }} />
            }
          </EmailHeader>
          
          {expandedEmails[email.id] && (
            <EmailContent>
              {renderEmailContent(email)}
            </EmailContent>
          )}
        </EmailThread>
      ))}
    </div>
  );
  const renderSingleEmail = () => (
    <div className="space-y-4">
        <EmailThread key={singleEmail?.id}>
          <EmailHeader>
            <div className="w-100">
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginBottom: '8px' }}>
            
              <h5 className="mb-0 mt-2">{singleEmail?.subject}</h5>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <CalendarOutlined style={{ color: '#666' }} />
          <span style={{ color: '#666' }}>{formatDate(singleEmail?.timestamp)}</span>
        </div>
              
      </div>
            </div>
       
          </EmailHeader>
          
          <EmailContent>
            {renderEmailContent(singleEmail)}
          </EmailContent>
        </EmailThread>
    </div>
  );

  return (
    <PageBackground>
      <Container fluid>
        {showHeader && (
          <>
            <Card className='d-none d-lg-block'>
              <Steps
                items={[
                  {
                    title: 'Dashboard',
                    status: 'finish',
                    icon: <DashboardOutlined />,
                  },
                  {
                    title: 'Onboarding',
                    status: 'finish',
                    icon: <UserOutlined />,
                  },
                  {
                    title: 'Customize AI Personality',
                    status: 'finish',
                    icon: <RobotOutlined />,
                  },
                  {
                    title: 'Workflow & Role',
                    status: 'finish',
                    icon: <ApartmentOutlined />,
                  },
                  {
                    title: 'Data Integration & Training',
                    status: 'finish',
                    icon: <DatabaseOutlined />,
                  },
                  {
                    title: 'Connect Calendly',
                    status: 'finish',
                    icon: <CalendarOutlined />,
                  },
                  {
                    title: 'Review Samples',
                    status: 'process',
                    icon: <FileSearchOutlined />,
                  },
                  {
                    title: 'Deploy Agents',
                    status: 'wait',
                    icon: <RocketOutlined />,
                  }
                ]}
              />
            </Card>
            
            <Card className='p-3 d-sm-block d-none d-lg-none'>
              <Steps
                items={[
                  {
                    status: 'finish',
                    icon: <DashboardOutlined />,
                  },
                  {
                    status: 'finish',
                    icon: <UserOutlined />,
                  },
                  {
                    status: 'finish',
                    icon: <RobotOutlined />,
                  },
                  {
                    status: 'finish',
                    icon: <ApartmentOutlined />,
                  },
                  {
                    status: 'finish',
                    icon: <DatabaseOutlined />,
                  },
                  {
                    status: 'finish',
                    icon: <CalendarOutlined />,
                  },
                  {
                    status: 'process',
                    icon: <FileSearchOutlined />,
                  },
                  {
                    status: 'wait',
                    icon: <RocketOutlined />,
                  }
                ]}
              />
            </Card>
          </>
        )}
        
        <div className="d-flex flex-column flex-md-row mt-4">
          {showHeader && (
            <Card className="p-2 mb-3 mb-md-0 me-md-3 d-none d-md-block">
              <SideMenu currentStep={7} activePage="/review-samples" />
            </Card>
          )}
          
          <div className='mb-3'>
            <Button type="primary" className='d-block d-md-none' onClick={showDrawer}>
              Navigation Menu
            </Button>
          </div>
          
          <Drawer title="Navigation Menu" onClose={onClose} open={open}>
            {showHeader && <SideMenu currentStep={7} activePage="/review-samples" />}
          </Drawer>
          
          <div className="w-100 ps-md-4">
            <Card>
              {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                  <Spinner animation="border" role="status" />
                </div>
              ) : (
                <>
                  <div className="mb-4">
                    <div className="d-flex align-items-start gap-3">
                      <div>

                      <img
                        src="https://via.placeholder.com/50"
                        alt="Profile"
                        onError={(e) => {
                          e.target.style.display = 'none';
                          const initials = personName?.split(' ')
                            .map(word => word[0])
                            .join('')
                            .toUpperCase()
                            .slice(0, 2);
                            
                          const fallbackDiv = document.createElement('div');
                          fallbackDiv.className = 'avatar';
                          fallbackDiv.style.cssText = `
                            background-color: #e0e0e0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #000000;
                            font-weight: 500;
                            font-size: 14px;
                            padding: 20px;
                          `;
                          fallbackDiv.textContent = initials;
                          e.target.parentNode.appendChild(fallbackDiv);
                        }}

                        style={{ width: '50px', height: '50px', borderRadius: '50%' }}
                      />
                      </div>
                      
                      <div>
                        <h5 className="mb-1">{personName}</h5>
                        {renderSocialIcons()}
                        {
                          companyName && (
                        <p className="mb-0 mt-2 text-muted">
                          <strong>Company:</strong> {companyName}
                        </p>

                          )
                        }
                      </div>
                    </div>
                  </div>
                  {
                    fromLocation=='review_samples'?(<>
                    {
                      renderSingleEmail()
                    }
                    </>):(
                    <>  
                  <div className="mb-3">
                    <p className="text-secondary fw-bold">
                      CONVERSATION HISTORY WITH {personName?.toUpperCase()}
                    </p>
                  </div>

                  <div>
                    {renderEmailChain()}
                  </div>
                    </>)
                    
                  }


                  <div className="text-center mt-4">
                    <Button
                      variant="primary"
                      className="continue-btn"
                      size="lg"
                      onClick={handleContinue}
                      disabled={continueBtnLoading}
                    >
                      {continueBtnLoading ? (
                        <Spinner animation="border" role="status" />
                      ) : (
                        'Continue'
                      )}
                    </Button>
                  </div>
                </>
              )}
            </Card>
          </div>
        </div>
      </Container>
    </PageBackground>
  );
}

export default EmailDisplay;
