import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Carousel, Spinner } from 'react-bootstrap';
import { Button, Input } from "antd";
import Header from '../components/Header';
import Lottie from 'react-lottie';
import animationData from '../assets/LandingAnimation.json';
import connectAnimation from '../assets/connect.json';
import pptxgen from 'pptxgenjs';
import { FiDownload } from "react-icons/fi";
import domtoimage from 'dom-to-image';


import "./AILanding.scss";

const convertContent = (htmlString) => {
    // For parsing HTML string
    const div = document.createElement('div');
    div.innerHTML = htmlString;

    // Find any ul elements and convert them to ol
    const ulElements = div.getElementsByTagName('ul');
    while (ulElements.length > 0) {
        const ul = ulElements[0];
        const ol = document.createElement('ol');
        
        // Copy all list items
        while (ul.firstChild) {
            ol.appendChild(ul.firstChild);
        }
        
        // Replace ul with ol
        ul.parentNode.replaceChild(ol, ul);
    }

    return div.innerHTML;
};
function AILanding() {
    const location = useLocation();
    const showHeader = location.state?.showHeader || false;
    const [content, setContent] = useState({
        welcome_slide: '',
        our_cohort_introduction: '',
        our_proposal_for_the_meeting: '',
        our_meeting_agenda: '',
    });
    const [firstName, setFirstName] = useState('');
    const [email, setEmail] = useState('');
    const [pitchingCompanyFirstName, setPitchingCompanyFirstName] = useState('');
    const [pitchingCompanyName, setPitchingCompanyName] = useState('');
    const [pitchingCompanyLogoUrl, setPitchingCompanyLogoUrl] = useState('');
    const [targetCompanyLogoUrl, setTargetCompanyLogoUrl] = useState('');
    const [targetCompanyName, setTargetCompanyName] = useState('');
    const [review_one, setReviewOne] = useState('');
    const [review_two, setReviewTwo] = useState('');
    const [messages, setMessages] = useState([]); // Initialize as empty array
    const [inputMessage, setInputMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [pitchingCompanyUserId, setPitchingCompanyUserId] = useState("")
    const [timeSlots, setTimeSlots] = useState([]);
    const [progress, setProgress] = useState(0); // State to manage progress
    const [currentSlide, setCurrentSlide] = useState(0); // State to manage current slide
    const [isPresentationLoading,setIsPresentationLoading]=useState(false)
    const [isBotTyping, setIsBotTyping] = useState(false); // New state for bot typing effect

    const totalSlides = (review_one || review_two) ? 5 : 4;
    // Add ref for chat messages container
    const messagesEndRef = useRef(null);
     // Add scroll to bottom function
     const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    // Add useEffect to scroll on messages update
    useEffect(() => {
        scrollToBottom();
    }, [messages]);  // Scroll whenever messages array updates


    useEffect(() => {
        const fetchContent = async () => {
            setIsLoading(true);
            try {
                const urlParams = new URLSearchParams(location.search);
                const email = urlParams.get('email') || '';
                const documentId = urlParams.get('documentId') || '';
                const source = urlParams.get('source') || '';

                // Fetch chat messages
                if (email && documentId) {
                    const chatResponse = await axios.get(
                        `${process.env.REACT_APP_API_URL}/aichat/landing_page_chat/get/?document_id=${documentId}&email=${email}`
                    );
                    if (chatResponse.data.messages && Array.isArray(chatResponse.data.messages)) {
                        setMessages(chatResponse.data.messages);
                    } else {
                        setMessages([]); // Ensure messages is always an array
                    }
                }

                const response = await axios.post(`${process.env.REACT_APP_API_URL}/integration/generate_landing_page`, {
                    person_email: email,
                    document_id: documentId,
                    source: source
                });
    
                if (response.data && response.data.content) {
                    let parsedContent;
                    try {
                        parsedContent = typeof response.data.content === 'string' 
                            ? JSON.parse(response.data.content) 
                            : response.data.content;
                        
                        // Convert UL to OL in specific sections
                        if (parsedContent.our_proposal_for_the_meeting) {
                            parsedContent.our_proposal_for_the_meeting = convertContent(parsedContent.our_proposal_for_the_meeting);
                        }
                        if (parsedContent.our_meeting_agenda) {
                            parsedContent.our_meeting_agenda = convertContent(parsedContent.our_meeting_agenda);
                        }
                        
                        setContent(parsedContent);
                        
                    } catch (e) {
                        console.error('Error parsing content:', e);
                        setContent(response.data.content);
                    }
    
                    setFirstName(response.data.first_name || 'there');
                    setEmail(response.data.email);
                    setPitchingCompanyFirstName(response.data.pitching_company_first_name || '');
                    setPitchingCompanyName(response.data.pitching_company_name);
                    setPitchingCompanyLogoUrl(response.data.pitching_company_logo_url);
                    setTargetCompanyLogoUrl(response.data.target_company_logo_url);
                    setPitchingCompanyUserId(response.data.pitching_company_user_id);
                    setTargetCompanyName(response.data.target_company_name);
                    setReviewOne(response.data.review_one);
                    setReviewTwo(response.data.review_two);
                }
    
                const slotsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/meetings/get_available_slots`);
                if (slotsResponse.data) {
                    setTimeSlots(slotsResponse.data);
                }
            } catch (error) {
                console.error('Error fetching content:', error);
            } finally {
                setIsLoading(false);
            }
        };
    
        fetchContent();
    }, [location]);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prev) => {
                if (prev >= 100) {
                    setCurrentSlide((prevSlide) => {
                        return (prevSlide + 1 < totalSlides) ? prevSlide + 1 : 0; // Go to next slide or wrap to the first
                    });
                    return 0; // Reset progress
                }
                return Math.min(prev + 1, 100); // Increment progress smoothly
            });
        }, 100); // Update every 100ms for smoother progress

        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    // Function to handle slide change
    const handleSelect = (selectedIndex) => {
        setCurrentSlide(selectedIndex);
        setProgress(0); // Reset progress when slide changes
    };

    const handleSendMessage = async () => {
        const userMessage = inputMessage.trim();
        if (userMessage === '') return;

        const urlParams = new URLSearchParams(location.search);
        const documentId = urlParams.get('documentId');
        const userEmail = urlParams.get('email');

        // Add user message
        const updatedMessages = [...messages, { sender: 'user', text: userMessage }];
        setMessages(updatedMessages);
        setInputMessage('');

        // Simulate bot typing
        setIsBotTyping(true);
        setTimeout(async () => {
            try {
                const response = await axios.post(process.env.REACT_APP_API_URL + '/integration/chat_response', {
                    message: userMessage,
                    document_id: documentId,
                    company_name: targetCompanyName,
                    email: email
                });

                const botMessage = response.data.reply || "We can set up a meeting to answer all your queries.";
                const finalMessages = [...updatedMessages, { sender: 'bot', text: botMessage }];
                
                try {
                    // Save chat messages first
                    if (documentId && userEmail) {
                        await axios.post(`${process.env.REACT_APP_API_URL}/aichat/landing_page_chat/save/`, {
                            document_id: documentId,
                            email: userEmail,
                            messages: finalMessages
                        });
                    }
                    // Only update state if save was successful
                    setMessages(finalMessages);
                } catch (error) {
                    console.error('Error saving chat messages:', error);
                    // Still update local state even if save failed
                    setMessages(finalMessages);
                }
            } catch (error) {
                console.error('Error sending message:', error);
                setMessages((prevMessages) => [...prevMessages, { sender: 'bot', text: "There was an issue with your request. Please try again." }]);
            } finally {
                setIsBotTyping(false); // Stop typing effect
            }
        }, 1000); // Simulate typing delay (1 second)
    };

    const handleInputChange = (e) => {
        setInputMessage(e.target.value);
    };

    const handleInputKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSendMessage();
        }
    };

    const handleScheduleClick = (slot) => {
        const urlParams = new URLSearchParams(location.search);
        const documentId = urlParams.get('documentId') || '';

        const scheduleMeetingUrl = `/schedule-meeting?firstName=${encodeURIComponent(firstName)}&email=${encodeURIComponent(email)}&documentId=${encodeURIComponent(documentId)}&pitchingCompanyUserId=${encodeURIComponent(pitchingCompanyUserId)}&selectedDate=${encodeURIComponent(slot.iso)}&selectedTime=${encodeURIComponent(slot.display)}`;

        window.location.href = scheduleMeetingUrl;
    };


const fetchImageAsBase64 = async (url) => {
    try {
        const img = new Image();
        img.crossOrigin = 'anonymous';
        
        const imageLoadPromise = new Promise((resolve, reject) => {
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.naturalWidth;
                canvas.height = img.naturalHeight;
                
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                
                try {
                    resolve(canvas.toDataURL('image/png'));
                } catch (err) {
                    reject(err);
                }
            };
            
            img.onerror = () => reject(new Error('Failed to load image'));
        });

        // Add cache buster to URL
        const cacheBuster = `${url}${url.includes('?') ? '&' : '?'}cb=${new Date().getTime()}`;
        img.src = cacheBuster;

        // Add timeout
        const timeoutPromise = new Promise((_, reject) => {
            setTimeout(() => reject(new Error('Image load timeout')), 10000);
        });

        return await Promise.race([imageLoadPromise, timeoutPromise]);
    } catch (error) {
        console.warn('Error fetching image:', error);
        return null;
    }
};

const processSlideImages = async (slideClone) => {
    const images = slideClone.querySelectorAll('img');
    await Promise.all(Array.from(images).map(async (img) => {
        if (!img.src) {
            handleImageFallback(img);
            return;
        }

        try {
            const isLogoImage = img.closest('.logo-box') !== null;
            const originalSrc = img.src;

            // Create a container div with red background
            const containerDiv = document.createElement('div');
            Object.assign(containerDiv.style, {
                width: '100px',
                height: '100px',
                backgroundColor: '#FFFFFF',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px',
                borderRadius: '4px',
                boxSizing: 'border-box'
            });

            // Create the white inner container
            const whiteContainer = document.createElement('div');
            Object.assign(whiteContainer.style, {
                width: '100px',
                height: '100px',
                backgroundColor: '#FFFFFF',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '4px'
            });

            // Create new image element
            const newImg = new Image();
            newImg.crossOrigin = 'anonymous';

            Object.assign(newImg.style, {
                maxWidth: '100px',
                maxHeight: '100px',
                objectFit: 'contain',
                borderRadius: '2px'
            });

            const base64Data = await fetchImageAsBase64(originalSrc);
            if (!base64Data) {
                throw new Error('Failed to convert image');
            }

            newImg.src = base64Data;
            newImg.setAttribute('data-processed', 'true');

            // Assemble the structure
            whiteContainer.appendChild(newImg);
            containerDiv.appendChild(whiteContainer);
            img.parentNode.replaceChild(containerDiv, img);

            await new Promise((resolve, reject) => {
                newImg.onload = resolve;
                newImg.onerror = () => {
                    handleImageFallback(img);
                    reject();
                };
                setTimeout(() => reject(new Error('Image load timeout')), 5000);
            });

        } catch (error) {
            console.warn('Error processing image:', error);
            handleImageFallback(img);
        }
    }));
};

const handleDownloadPPTX = async () => {
    try {
        setIsPresentationLoading(true);
        const pres = new pptxgen();
        pres.layout = 'LAYOUT_16x9';


        const getBackgroundGif = async () => {
            try {
                const response = await fetch('/background.gif');
                if (!response.ok) throw new Error('Failed to load background');
                const blob = await response.blob();
                return new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.readAsDataURL(blob);
                });
            } catch (error) {
                console.warn('Error loading background:', error);
                return null;
            }
        };

         console.log('Loading background GIF...');
        const backgroundGif = await getBackgroundGif();
        if (!backgroundGif) {
            throw new Error('Failed to load background GIF');
        }
        console.log('Background GIF loaded successfully');
        // Clone all slides first to avoid interference with carousel
        const slides = Array.from(document.querySelectorAll('.carousel-item'))
            .map(slide => slide.cloneNode(true));
        
        // Create temp container
        const tempContainer = document.createElement('div');
        Object.assign(tempContainer.style, {
            position: 'absolute',
            left: '-9999px',
            top: '-9999px',
            width: '1920px',
            height: '1080px',
            backgroundImage: 'url(/background.gif)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            overflow: 'hidden'
        });
        document.body.appendChild(tempContainer);

        // Process each slide
        for (const [index, slideClone] of slides.entries()) {
            try {
                // Remove animations
                // slideClone.querySelectorAll('.Lottie, #stars, #stars2, #stars3, #particle')
                //     .forEach(elem => elem.remove());
                
                // Process images first
                await processSlideImages(slideClone);

                // Style the slide
                Object.assign(slideClone.style, {
                    width: '1920px',
                    height: '1080px',
                    position: 'relative',
                    backgroundColor: 'transparent',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                });


                // Ensure content is above overlay
                const contentContainer = slideClone.querySelector('.d-flex');
                if (contentContainer) {
                    Object.assign(contentContainer.style, {
                        position: 'relative',
                        zIndex: 2
                    });
                }

                // Style text elements
                slideClone.querySelectorAll('h4, h5, p, div').forEach(elem => {
                    if (elem.tagName === 'H4') {
                        Object.assign(elem.style, {
                            fontSize: '48px',
                            marginBottom: '30px',
                            fontWeight: '700',
                            fontFamily: 'Arial, sans-serif',
                            color: '#FFFFFF'
                        });
                    } else if (elem.tagName === 'H5') {
                        Object.assign(elem.style, {
                            fontSize: '36px',
                            marginBottom: '25px',
                            fontWeight: '600',
                            fontFamily: 'Arial, sans-serif',
                            color: '#FFFFFF'
                        });
                    } else if (elem.classList.contains('card')) {
                        Object.assign(elem.style, {
                            backgroundColor: 'rgba(255, 255, 255, 0.95)',
                            border: '2px solid white',
                            borderRadius: '10px',
                            padding: '30px',
                            margin: '20px',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                        });
                    } else if (!elem.closest('.card') && !elem.closest('.logo-box')) {
                        Object.assign(elem.style, {
                            fontSize: '24px',
                            lineHeight: '1.5',
                            color: '#FFFFFF',
                            textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
                            fontFamily: 'Arial, sans-serif'
                        });
                    }
                });

                // Style list elements
                slideClone.querySelectorAll('ol, ul').forEach(list => {
                    Object.assign(list.style, {
                        textAlign: 'left',
                        marginLeft: '40px',
                        color: '#FFFFFF',
                        paddingLeft: '20px',
                        listStylePosition: 'outside'
                    });
                
                    const isOrderedList = list.tagName.toLowerCase() === 'ol';
                    
                    list.querySelectorAll('li').forEach((li, index) => {
                        Object.assign(li.style, {
                            marginBottom: '10px',
                            fontSize: '24px',
                            color: '#FFFFFF',
                            paddingLeft: '10px',
                            display: 'list-item',
                            listStyleType: isOrderedList ? 'decimal' : 'disc',  // Numbers for ol, bullets for ul
                            listStylePosition: 'outside'
                        });
                
                        // Add custom numbering for ordered lists
                        if (isOrderedList) {
                            li.style.counterIncrement = 'item';
                            li.style.listStyleType = 'decimal';
                            li.setAttribute('value', index + 1); // Ensures proper numbering
                        } else {
                            // For unordered lists, add a more visible bullet
                            li.style.listStyleType = 'disc';
                            li.style.listStyleColor = '#FFFFFF';
                        }
                
                        // Add text shadow for better visibility
                        li.style.textShadow = '2px 2px 4px rgba(0,0,0,0.5)';
                
                        // Ensure proper spacing
                        li.style.lineHeight = '1.5';
                        li.style.marginTop = '5px';
                        
                        // Add some left padding for better alignment with numbers/bullets
                        li.style.paddingLeft = '10px';
                    });
                
                    // Add specific styles for nested lists if they exist
                    list.querySelectorAll('ol ol, ol ul, ul ul, ul ol').forEach(nestedList => {
                        Object.assign(nestedList.style, {
                            marginLeft: '30px',
                            marginTop: '5px'
                        });
                    });
                });

                // Clear container and add slide
                tempContainer.innerHTML = '';
                tempContainer.appendChild(slideClone);

                // Capture slide
                const dataUrl = await domtoimage.toPng(slideClone, {
                    width: 1920,
                    height: 1080,
                    quality: 1,
                    style: {
                        'transform': 'none',
                        'transform-origin': 'center'
                    },
                    filter: (node) => {
                        // Filter out any remaining animation elements
                        return !node.classList?.contains('Lottie') &&
                               !node.id?.match(/^(stars|particle)/);
                    }
                });

                // Add to presentation
                const slide = pres.addSlide();
                slide.background = { data: backgroundGif };
                slide.addImage({
                    data: dataUrl,
                    x: 0,
                    y: 0,
                    w: '100%',
                    h: '100%',
                    sizing: {
                        type: 'contain',
                        w: pres.presLayout.width,
                        h: pres.presLayout.height
                    }
                });
                slide.transition = { type: 'fade' };
            } catch (error) {
                console.error(`Error processing slide ${index + 1}:`, error);
                const slide = pres.addSlide();
                slide.addText(`Slide ${index + 1} (Error: ${error.message})`, {
                    x: 1,
                    y: 1,
                    w: '80%',
                    h: '80%',
                    fontSize: 24,
                    color: 'FF0000'
                });
            }
        }

        // Cleanup
        document.body.removeChild(tempContainer);

        // Save presentation
        const date = new Date().toISOString().split('T')[0];
        const fileName = `${pitchingCompanyName}_presentation_${date}.pptx`;
        await pres.writeFile({ fileName });

    } catch (error) {
        console.error('Error generating PPTX:', error);
        alert('There was an error generating the presentation. Please try again.');
    } finally {
        setIsPresentationLoading(false);
    }
};
// Updated fallback handler to match new structure
const handleImageFallback = (img) => {
    const containerDiv = document.createElement('div');
    Object.assign(containerDiv.style, {
        width: '100px',
        height: '100px',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px',
        borderRadius: '4px',
        boxSizing: 'border-box'
    });

    const whiteContainer = document.createElement('div');
    Object.assign(whiteContainer.style, {
        width: '100px',
        height: '100px',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px'
    });

    const textNode = document.createElement('div');
    Object.assign(textNode.style, {
        color: '#000000',
        fontSize: '16px',
        fontWeight: '500',
        textAlign: 'center',
        fontFamily: 'Arial, sans-serif'
    });

    // Determine which company name to display
    const logoBoxes = img.closest('.logo-box');
    const isPitchingCompany = Array.from(document.querySelectorAll('.logo-box')).indexOf(logoBoxes) === 0;
    textNode.innerText = isPitchingCompany ? pitchingCompanyName : targetCompanyName;

    whiteContainer.appendChild(textNode);
    containerDiv.appendChild(whiteContainer);
    
    if (img.parentNode) {
        img.parentNode.replaceChild(containerDiv, img);
    }
};
// Updated LogoBox component with better image handling
const LogoBox = ({ logoUrl, companyName }) => {
    return (
        <span className='logo-box d-flex align-items-center justify-content-center'>
            {logoUrl ? (
                <div style={{
                    width: '100px',
                    height: '100px',
                    backgroundColor: '#FFFFFF',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '10px',
                    borderRadius: '4px',
                    boxSizing: 'border-box'
                }}>
                    <div style={{
                        width: '100px',
                        height: '100px',
                        backgroundColor: '#FFFFFF',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px'
                    }}>
                        <img 
                            src={logoUrl} 
                            alt={companyName}
                            onError={(e) => {
                                const textNode = document.createElement('div');
                                textNode.innerText = companyName;  // Using companyName here
                                Object.assign(textNode.style, {
                                    color: '#000000',
                                    fontSize: '16px',
                                    fontWeight: '500',
                                    textAlign: 'center',
                                    fontFamily: 'Arial, sans-serif'
                                });
                                e.target.parentNode.replaceChild(textNode, e.target);
                            }}
                            style={{
                                maxWidth: '100px',
                                maxHeight: '100px',
                                objectFit: 'contain',
                                borderRadius: '2px'
                            }}
                        />
                    </div>
                </div>
            ) : (
                <div style={{
                    width: '100px',
                    height: '100px',
                    backgroundColor: '#FFFFFF',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '10px',
                    borderRadius: '4px'
                }}>
                    <div style={{
                        width: '100px',
                        height: '100px',
                        backgroundColor: '#FFFFFF',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px'
                    }}>
                        <div style={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '600',
                            textAlign: 'center',
                            fontFamily: 'Arial, sans-serif'
                        }}>
                            {companyName}
                        </div>
                    </div>
                </div>
            )}
        </span>
    );
};
    // Updated Lottie animation component
    const LottieAnimation = ({ width = '200px', height = '200px' }) => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return (
            <div style={{ width, height, margin: '0 auto 20px' }} className="animate__animated animate__zoomIn">
                <Lottie options={defaultOptions} />
            </div>
        );
    };

    if (isLoading) {
        return (
            <div className="d-flex flex-column justify-content-center align-items-center vh-100">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                <p className="mt-3">AI is generating your pitch deck</p>
            </div>
        );
    }
    const defaultOptions2 = {
        loop: true,
        autoplay: true,
        animationData: connectAnimation,
        rendererSettings: {
            preserveAspectRatio: 'fxMidYMid slice'
        }
    };

    const createMarkup = (html) => {
        return { __html: html };
    };

    return (
        <>
            {showHeader && <Header />}
            <Container fluid>
                <Row style={{height: showHeader ? 'calc(100vh - 62px)' : '100vh'}}>
                    <Col md={8} className='px-0 h-100 position-relative'>
                        <Carousel activeIndex={currentSlide} onSelect={handleSelect} className='wrapper h-100' indicators={false} interval={null}>
                            <Carousel.Item>
                                <div id='stars'></div>
                                <div id='stars2'></div>
                                <div id='stars3'></div>
                                <div id="particle"></div>
                                <div className="d-flex align-items-center justify-content-center  w-75 h-75 m-auto">
                                    <div className='text-center'>
                                        <LottieAnimation width="300px" height="300px" />
                                        <div className='d-flex justify-content-center'>
                                            <LogoBox logoUrl={pitchingCompanyLogoUrl} companyName={pitchingCompanyName} />
                                            <LogoBox logoUrl={targetCompanyLogoUrl} companyName={targetCompanyName} />
                                        </div>
                                        <div className='pt-4'>
                                        <h2 className='text-white animate__animated animate__fadeInUp' dangerouslySetInnerHTML={createMarkup(content.welcome_slide)} />
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                            <div id='stars'></div>
                        <div id='stars2'></div>
                        <div id='stars3'></div>
                        <div id="particle"></div>
                                <div className="d-flex align-items-center justify-content-center  w-75 h-100 m-auto">
                                    <div className='text-center'>
                                        <div className='d-flex justify-content-center'>
                                            <LogoBox logoUrl={pitchingCompanyLogoUrl} companyName={pitchingCompanyName} />
                                            <Lottie options={defaultOptions2} style={{margin:"0"}} height={150}  width={150}/>
                                            <LogoBox logoUrl={targetCompanyLogoUrl} companyName={targetCompanyName} />
                                        </div>
                                        <h3 className='text-white fw-bold'>OUR COHORT INTRODUCTION</h3>
                                        <div className='text-center'>
                                            <p className='text-white ' dangerouslySetInnerHTML={createMarkup(content.our_cohort_introduction)} />
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                            <div id='stars'></div>
                        <div id='stars2'></div>
                        <div id='stars3'></div>
                        <div id="particle"></div>
                                <div className="d-flex align-items-center justify-content-center  w-75 h-100 m-auto">
                                    <div className='text-center'>
                                        <h3 className='text-white fw-bold '>OUR PROPOSAL FOR THE MEETING</h3>
                                        <div className='text-start'>
                                        <p className='text-white ' dangerouslySetInnerHTML={createMarkup(content.our_proposal_for_the_meeting)} />

                                        </div>                                        <div className='d-flex justify-content-center'>
                                            <LogoBox logoUrl={pitchingCompanyLogoUrl} companyName={pitchingCompanyName} />
                                            <Lottie options={defaultOptions2} style={{margin:"0"}} height={150}  width={150}/>
                                            <LogoBox logoUrl={targetCompanyLogoUrl} companyName={targetCompanyName} />
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                            <div id='stars'></div>
                        <div id='stars2'></div>
                        <div id='stars3'></div>
                        <div id="particle"></div>
                                <div className="d-flex align-items-center justify-content-center  w-75 h-100 m-auto">
                                    <div className='text-center'>
                                        <h3 className='text-white fw-bold'>OUR MEETING AGENDA</h3>
                                        <div className='text-start'>
                                        <p className='text-white' dangerouslySetInnerHTML={createMarkup(content.our_meeting_agenda)} />

                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            <LogoBox logoUrl={pitchingCompanyLogoUrl} companyName={pitchingCompanyName} />
                                            <Lottie options={defaultOptions2} style={{margin:"0"}} height={150}  width={150}/>
                                            <LogoBox logoUrl={targetCompanyLogoUrl} companyName={targetCompanyName} />
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Item>
                            {(review_one || review_two) && (
                                <Carousel.Item>
                                    <div id='stars'></div>
                                    <div id='stars2'></div>
                                    <div id='stars3'></div>
                                    <div id="particle"></div>
                                    <div className="d-flex align-items-center justify-content-center w-75 h-100 m-auto">
                                        <div>
                                            <h4 className='text-white text-center fw-bold'>Our Testimonials</h4>
                                            <div className='pt-3 mb-4'>
                                                <Row>
                                                    {review_one && review_two ? (
                                                        // Both reviews present - show two columns
                                                        <>
                                                            <Col>
                                                                <div className="card p-4">
                                                                    <p className='mb-0 fs-5'>&ldquo;{review_one}&rdquo;</p>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className='card p-4'>
                                                                    <p className='mb-0 fs-5'>&ldquo;{review_two}&rdquo;</p>
                                                                </div>
                                                            </Col>
                                                        </>
                                                    ) : (
                                                        // Only one review present - show single column with full width
                                                        <Col xs={12}>
                                                            <div className='card p-4'>
                                                                <p className='mb-0 fs-5'>&ldquo;{review_one || review_two}&rdquo;</p>
                                                            </div>
                                                        </Col>
                                                    )}
                                                </Row>
                                            </div>
                                            <div className='d-flex justify-content-center'>
                                                <LogoBox logoUrl={pitchingCompanyLogoUrl} companyName={pitchingCompanyName} />
                                                <Lottie options={defaultOptions2} style={{margin:"0"}} height={150} width={150}/>
                                                <LogoBox logoUrl={targetCompanyLogoUrl} companyName={targetCompanyName} />
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                            )}


                        </Carousel>
                        <Button
                            onClick={handleDownloadPPTX}
                            disabled={isPresentationLoading}
                            type="primary"
                            className='position-absolute bottom-0 end-0 me-5 mb-4 download-btn'
                            style={{ zIndex: '99' }}
                            size='large'
                        >
                            <FiDownload  className="d-inline-block" />
                            {isPresentationLoading ? 'Loading…' : 'Download'}
                        </Button>
                    </Col>
                    <Col md={4} className='px-0'>
                        <div className='chatbox d-flex flex-column h-100'>
                            <div className='chat-msg p-3 flex-grow-1 overflow-auto'>
                                <div className='p-3  rounded bg-light'>
                                    <div className='d-flex mb-3 align-items-center'>
                                        <LogoBox logoUrl={pitchingCompanyLogoUrl} companyName={pitchingCompanyName} />
                                        <span className="mx-2"><strong>{pitchingCompanyFirstName}</strong></span>
                                        <span className='fs-3 fw-bold lh-1'>{pitchingCompanyName}</span>
                                    </div>
                                    <p>Hey {firstName}! I'm {pitchingCompanyFirstName}. We've worked with similar companies, and I would love to share more about {pitchingCompanyName}.</p>
                                    <p>We are excited to meet you, and I will try and provide tailored insights for you during our meeting.</p>
                                    <p>I've blocked some availability on my calendar for you.</p>
                                    <p>Please feel free to book a meeting slot with me through this link: full calendar slots or pick whichever slot is convenient for you from the options below:</p>
                                    <div>
                                        {timeSlots.map((slot, index) => (
                                            <button
                                                key={index}
                                                onClick={() => handleScheduleClick(slot)}
                                                type='button'
                                                className='mb-2 me-2 btn btn-outline-primary'
                                            >
                                                {slot.display}
                                            </button>
                                        ))}
                                        <button
                                                onClick={() => handleScheduleClick({'iso': '', 'display': ''})} 
                                                type='button'
                                                className='mb-2 me-2 btn btn-primary'
                                            >
                                                Show More Slots
                                        </button>
                                    </div>
                                    <p>Do you have any questions for us so far?</p>
                                </div>
                                <div className='chat-msgs pt-2 mt-2'>
                                    {messages.map((message, index) => (
                                        <div key={index} className={`d-flex mb-2 ${message.sender === 'user' ? 'justify-content-end' : 'justify-content-start'}`}>
                                            <div className={`p-2 rounded ${message.sender === 'user' ? 'bg-primary text-white' : 'bg-light text-dark'}`}>
                                                {message.sender === 'user' ? (
                                                    message.text
                                                ) : (
                                                    <ReactMarkdown>{message.text}</ReactMarkdown>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                    {isBotTyping && ( // Show animated typing indicator
                                        <div className='d-flex mb-2 justify-content-start'>
                                            <div className='typing-indicator bg-light px-2 py-3 rounded lh-1'>
                                                <div className='dot'></div>
                                                <div className='dot'></div>
                                                <div className='dot'></div>
                                            </div>
                                        </div>
                                    )}
                                    <div ref={messagesEndRef} />
                                </div>
                            </div>
                            <div className='send-msgss border-top p-2'>
                                <div className='d-flex'>
                                    <Input
                                        type="text"
                                        placeholder='Type your response here and press [Enter]'
                                        className='me-2'
                                        value={inputMessage}
                                        onChange={handleInputChange}
                                        onKeyPress={handleInputKeyPress}
                                    />
                                    <Button type="primary" color='#f3f3f3' className='h-full' onClick={handleSendMessage}>Send</Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            {/* Rounded Progress Indicator */}
            <div style={{ position: 'absolute', bottom: '20px', left: '20px'}}>
                <svg width="60" height="60">
                    <circle cx="30" cy="30" r="25" stroke="lightgray" strokeWidth="5" fill="none" />
                    <circle
                        cx="30"
                        cy="30"
                        r="25"
                        stroke="#1677ff"
                        strokeWidth="5"
                        fill="none"
                        strokeDasharray="157" // Circumference of the circle (2 * π * r) for r = 25
                        strokeDashoffset={157 - (progress / 100) * 157} // Calculate the offset based on progress
                        style={{ transition: 'stroke-dashoffset 0.1s linear' }}
                    />
                    <text x="30" y="35" textAnchor="middle" fill="white" fontSize="15" fontWeight="bold">
                        {currentSlide + 1}/{totalSlides} {/* Display current slide number */}
                    </text>
                </svg>
            </div>
        </>
    );
}

export default AILanding;
