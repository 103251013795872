import React, { useState, useEffect, useCallback } from 'react';
import { Layout, Tabs, Button, Table, Input, Space, Select, Typography, Dropdown, Menu, message, Form, Modal, Card } from 'antd';
import { PlusOutlined, SearchOutlined, FilterOutlined, DownOutlined, MoreOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import './Contacts.css';
import { useSelector } from 'react-redux';

const { Content } = Layout;
const { Option } = Select;
const { TabPane } = Tabs;
const { Title } = Typography;
const { confirm } = Modal;

const Contacts = () => {
  const [activeTab, setActiveTab] = useState('all');
  const { userId } = useSelector(state => state.app);
  const [filterSidebarVisible, setFilterSidebarVisible] = useState(false);
  const [newContactSidebarVisible, setNewContactSidebarVisible] = useState(false);
  const [editContactSidebarVisible, setEditContactSidebarVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [savingContact, setSavingContact] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  });
  const [filters, setFilters] = useState({
    search: '',
    user_id: String(userId),
    country: '',
    company: '',
    jobTitle: ''
  });

  
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <a href="#">{text}</a>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Company',
      dataIndex: 'company_name',
      key: 'company_name',
    },
    {
      title: 'Job Title',
      dataIndex: 'job_title',
      key: 'job_title',
    },
    {
      title: 'Phone',
      dataIndex: 'phone_number',
      key: 'phone_number',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Dropdown overlay={() => actionMenu(record)} trigger={['click']}>
          <Button icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  const actionMenu = (record) => (
    <Menu>
      <Menu.Item key="1" onClick={() => handleEdit(record)}>Edit</Menu.Item>
      <Menu.Item key="2" onClick={() => handleDelete(record)}>Delete</Menu.Item>
    </Menu>
  );

  const handleEdit = (contact) => {
    setSelectedContact(contact);
    editForm.setFieldsValue(contact);
    setEditContactSidebarVisible(true);
  };

  const handleDelete = (contact) => {
    confirm({
      title: 'Are you sure you want to delete this contact?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteContact(contact);
      },
    });
  };

  const deleteContact = async (contact) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/emailapp/delete_enquiry_contact`,
        { data: { _id: contact._id } }
      );
      
      if (response.data.message) {
        message.success('Contact deleted successfully');
        fetchContacts();
      }
    } catch (error) {
      message.error('Failed to delete contact');
      console.error('Error deleting contact:', error);
    }
  };

  const handleUpdateContact = async () => {
    try {
      const values = await editForm.validateFields();
      setSavingContact(true);

      const trimmedValues = Object.keys(values).reduce((acc, key) => {
        acc[key] = typeof values[key] === 'string' ? values[key].trim() : values[key];
        return acc;
      }, {});

      const contactData = {
        ...trimmedValues,
        _id: selectedContact._id,
        user_id: userId
      };
  
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/emailapp/update_enquiry_contact`,
        contactData
      );

      if (response.data.message) {
        message.success('Contact updated successfully');
        setEditContactSidebarVisible(false);
        fetchContacts();
      }
    } catch (error) {
      if (error.response) {
        message.error(error.response.data.message || 'Failed to update contact');
      } else if (!error.isAxiosError) {
        console.error('Validation failed:', error);
        return;
      } else {
        message.error('Failed to update contact');
      }
      console.error('Error updating contact:', error);
    } finally {
      setSavingContact(false);
    }
  };

  const fetchContacts = async () => {
    try {
      setLoading(true);
      const requestData = {
        page: pagination.current,
        pageSize: pagination.pageSize,
        ...filters
      };
      
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/emailapp/get_contacts`,requestData 
      );
      
      setContacts(response.data.contacts);
      setPagination({
        ...pagination,
        total: response.data.total
      });
    } catch (error) {
      message.error('Failed to fetch contacts');
      console.error('Error fetching contacts:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContacts();
  }, [pagination.current, pagination.pageSize, filters]);

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const debouncedSearch = useCallback(
    (() => {
      let timeoutId;
      return (value) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          setFilters(prev => ({ ...prev, search: value.trim() }));
          setPagination(prev => ({ ...prev, current: 1 }));
        }, 500);
      };
    })(),
    []
  );

  const handleFilterChange = (key, value) => {
    setFilters({ ...filters, [key]: value });
    setPagination({ ...pagination, current: 1 });
  };

  const toggleFilterSidebar = () => {
    setFilterSidebarVisible(!filterSidebarVisible);
  };

  const toggleNewContactSidebar = () => {
    setNewContactSidebarVisible(!newContactSidebarVisible);
    if (!newContactSidebarVisible) {
      form.resetFields();
    }
  };

  const toggleEditContactSidebar = () => {
    setEditContactSidebarVisible(!editContactSidebarVisible);
    if (!editContactSidebarVisible) {
      editForm.resetFields();
    }
  };

  const handleSaveContact = async () => {
    try {
      const values = await form.validateFields();
      setSavingContact(true);

      const trimmedValues = Object.keys(values).reduce((acc, key) => {
        acc[key] = typeof values[key] === 'string' ? values[key].trim() : values[key];
        return acc;
      }, {});

      const contactData = {
        ...trimmedValues,
        user_id: userId
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/emailapp/store_enquiry_contact`,
        contactData
      );

      if (response.status === 201) {
        message.success('Contact saved successfully');
        toggleNewContactSidebar();
        fetchContacts();
      } else {
        message.error('Failed to save contact');
      }
    } catch (error) {
      if (error.response) {
        message.error(error.response.data.message || 'Failed to save contact');
      } else if (!error.isAxiosError) {
        console.error('Validation failed:', error);
        return;
      } else {
        message.error('Failed to save contact');
      }
      console.error('Error saving contact:', error);
    } finally {
      setSavingContact(false);
    }
  };

  return (
    <Card style={{ margin: '24px' }}>
      <Layout style={{ background: '#fff' }}>
        <Content >
          <Title level={4}>Contacts</Title>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
            <Tabs activeKey={activeTab} onChange={setActiveTab} style={{ marginBottom: 0 }}>
              <TabPane tab="All" key="all" />
              <TabPane tab="My" key="my" />
            </Tabs>
            <Button type="primary" icon={<PlusOutlined />} onClick={toggleNewContactSidebar}>
              New Contact
            </Button>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <Space>
              <Select 
                defaultValue="all" 
                style={{ width: 120 }}
                onChange={(value) => handleFilterChange('user_id', value)}
              >
                <Option value="">All</Option>
                <Option value="current">My Contacts</Option>
              </Select>
              <Input
                placeholder="Search"
                prefix={<SearchOutlined />}
                style={{ width: 200 }}
                onChange={(e) => debouncedSearch(e.target.value)}
              />
              <Button onClick={toggleFilterSidebar}>
                Filters <DownOutlined />
              </Button>
            </Space>
          </div>
          <Table 
            columns={columns} 
            dataSource={contacts} 
            pagination={{
              ...pagination,
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: (total) => `Total ${total} items`
            }}
            onChange={handleTableChange}
            loading={loading}
            rowKey="_id"
          />
        </Content>

        {/* Filter Sidebar */}
        <div className={`sidebar ${filterSidebarVisible ? 'show' : ''}`}>
          <div className="sidebar-header">
            <h3>Filters</h3>
            <button className="close-btn" onClick={toggleFilterSidebar}>&times;</button>
          </div>
          <div className="sidebar-content">
            <Space direction="vertical" style={{ width: '100%' }}>
              <Select 
                style={{ width: '100%' }} 
                placeholder="Country"
                onChange={(value) => handleFilterChange('country', value)}
                allowClear
              >
                <Option value="">All Countries</Option>
                <Option value="USA">USA</Option>
                <Option value="UK">UK</Option>
                <Option value="Canada">Canada</Option>
              </Select>
              <Select 
                style={{ width: '100%' }} 
                placeholder="Company"
                onChange={(value) => handleFilterChange('company', value)}
                allowClear
              >
                <Option value="">All Companies</Option>
              </Select>
              <Select 
                style={{ width: '100%' }} 
                placeholder="Job Title"
                onChange={(value) => handleFilterChange('jobTitle', value)}
                allowClear
              >
                <Option value="">All Titles</Option>
              </Select>
            </Space>
          </div>
        </div>

        {/* New Contact Sidebar */}
        <div className={`sidebar ${newContactSidebarVisible ? 'show' : ''}`}>
          <div className="sidebar-header">
            <h5 className='mb-0'>New Contact</h5>
            <button className="close-btn" onClick={toggleNewContactSidebar}>&times;</button>
          </div>
          <div className="sidebar-content">
            <Form
              form={form}
              layout="vertical"
              >
              <Form.Item
                name="name"
                label="Name"
                style={{marginBottom: 15}}
                rules={[
                  { required: true, message: 'Please enter the name' },
                  { whitespace: true, message: 'Name cannot be empty spaces' }
                ]}
              >
                <Input placeholder="Enter name" />
              </Form.Item>

              <Form.Item
                name="email"
                label="Email"
                style={{marginBottom: 15}}
                rules={[
                  { required: true, message: 'Please enter the email' },
                  { type: 'email', message: 'Please enter a valid email' },
                  { whitespace: true, message: 'Email cannot be empty spaces' }
                ]}
              >
                <Input placeholder="Enter email" />
              </Form.Item>

              <Form.Item
                name="company_name"
                label="Company Name"
                style={{marginBottom: 15}}
                rules={[
                  { required: true, message: 'Please enter the company name' },
                  { whitespace: true, message: 'Company name cannot be empty spaces' }
                ]}
              >
                <Input placeholder="Enter company name" />
              </Form.Item>

              <Form.Item
                name="phone_number"
                label="Phone Number"
                style={{marginBottom: 15}}
                rules={[
                  { pattern: /^\d{10}$/,message: 'Please enter a valid 10-digit phone number',},
                  { whitespace: true, message: 'Phone Number cannot be empty spaces' }
                ]}
              >
                <Input placeholder="Enter phone number" />
              </Form.Item>

              <Form.Item
                name="job_title"
                label="Job Title"
                style={{marginBottom: 15}}
                rules={[
                  { whitespace: true, message: 'Job Title cannot be empty spaces' }
                ]}
              >
                <Input placeholder="Enter job title" />
              </Form.Item>

              <Form.Item
                name="country"
                label="Country"
                style={{marginBottom: 15}}
                rules={[
                  { whitespace: true, message: 'Country cannot be empty spaces' }
                ]}
                
              >
                <Select placeholder="Select country">
                  <Option value="USA">USA</Option>
                  <Option value="UK">UK</Option>
                  <Option value="Canada">Canada</Option>
                  <Option value="Australia">Australia</Option>
                  <Option value="India">India</Option>
                </Select>
              </Form.Item>

              <Form.Item>
                <Button 
                  type="primary" 
                  onClick={handleSaveContact} 
                  loading={savingContact}
                  block
                >
                  {savingContact ? 'Saving...' : 'Save Contact'}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>

        {/* Edit Contact Sidebar */}
        <div className={`sidebar ${editContactSidebarVisible ? 'show' : ''}`}>
          <div className="sidebar-header">
            <h5 className='mb-0'>Edit Contact</h5>
            <button className="close-btn" onClick={toggleEditContactSidebar}>&times;</button>
          </div>
          <div className="sidebar-content">
            <Form
              form={editForm}
              layout="vertical"
            >
              <Form.Item
                name="name"
                label="Name"
                style={{marginBottom: 15}}
                rules={[
                  { required: true, message: 'Please enter the name' },
                  { whitespace: true, message: 'Name cannot be empty spaces' }
                ]}
              >
                <Input placeholder="Enter name" />
              </Form.Item>

              <Form.Item
                name="email"
                label="Email"
                style={{marginBottom: 15}}
                rules={[
                  { required: true, message: 'Please enter the email' },
                  { type: 'email', message: 'Please enter a valid email' },
                  { whitespace: true, message: 'Email cannot be empty spaces' }
                ]}
              >
                <Input placeholder="Enter email" />
              </Form.Item>

              <Form.Item
                name="company_name"
                label="Company Name"
                style={{marginBottom: 15}}
                rules={[
                  { required: true, message: 'Please enter the company name' },
                  { whitespace: true, message: 'Company name cannot be empty spaces' }
                ]}
              >
                <Input placeholder="Enter company name" />
              </Form.Item>

              <Form.Item
                name="phone_number"
                label="Phone Number"
                style={{marginBottom: 15}}
                rules={[
                  { pattern: /^\d{10}$/,message: 'Please enter a valid 10-digit phone number',},
                  { whitespace: true, message: 'Phone Number cannot be empty spaces' }
                ]}
              >
                <Input placeholder="Enter phone number" />
              </Form.Item>

              <Form.Item
                name="job_title"
                label="Job Title"
                style={{marginBottom: 15}}
                rules={[
                  { whitespace: true, message: 'Job Title cannot be empty spaces' }
                ]}
              >
                <Input placeholder="Enter job title" />
              </Form.Item>

              <Form.Item
                name="country"
                label="Country"
                style={{marginBottom: 15}}
              >
                <Select placeholder="Select country">
                  <Option value="USA">USA</Option>
                  <Option value="UK">UK</Option>
                  <Option value="Canada">Canada</Option>
                  <Option value="Australia">Australia</Option>
                  <Option value="India">India</Option>
                </Select>
              </Form.Item>

              <Form.Item>
                <Button 
                  type="primary" 
                  onClick={handleUpdateContact} 
                  loading={savingContact}
                  block
                >
                  {savingContact ? 'Updating...' : 'Update Contact'}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Layout>
    </Card>
  );
};

export default Contacts;
